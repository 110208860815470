<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="公司名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item style="float:right">
            <!-- <el-button type="danger" @click="onDelete" v-if="form.guid !== ''"
              >删除</el-button
            > -->
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </el-container>
</template>

<script>
export default {
  data() {
    return {
      form: {
        guid: "",
        name: "",
      },
      userInfo: {},
      rules: {
        name: [{required: true, message: "公司名不能为空", trigger: "blur"}],
      },
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getCompanyDetail(self.$route.query.guid || null);
  },
  methods: {
    getCompanyDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/company/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            // self.form = response.data.data;
            self.form.guid = response.data.data.guid
            self.form.name = response.data.data.name;
          })
          .catch(function (error) {
            self.$message({
              message: error,
              type: "warning",
            });
          });
      }else{
        // self.getPatrolType();
      }
    },
    onSubmit() {
      let self = this;
      let params = self.form;
      // params.account = self.form.account;
      // params.name = self.form.name;
      // params.guid = self.form.guid;
      // params.password = self.form.password;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(params));
          self.$axios
            .post("/company/saveOrUpdate", JSON.stringify(data), {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function () {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "CompanyList" });
            })
            .catch(function (error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的公司信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    // onDelete() {
    //   let self = this;
    //   self
    //     .$confirm("确定删除 " + (self.form.account || "未命名用户") + "？")
    //     .then(() => {
    //       self.$axios
    //         .delete("/staff/delete", {
    //           data: { guid: self.form.guid },
    //           headers: { token: self.userInfo.token },
    //         })
    //         .then(() => {
    //           self.$message({
    //             message: "删除成功",
    //             type: "success",
    //           });
    //           self.$router.push({ name: "staff_list" });
    //         })
    //         .catch((error) => {
    //           self.$message({
    //             message: error.response.data.message,
    //             type: "warning",
    //           });
    //         });
    //     })
    //     .catch(() => {});
    // },

  },
};
</script>

<style>
</style>
